import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteUser, User } from 'firebase/auth';
import { collection, getDocs, query, QuerySnapshot, where, Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from '../firebase';
import { Profile } from '../interfaces';
import { ProfileError } from '../utils/classes';
import { serializeDate } from '../utils/dates';
import { patchDoc } from '../utils/firebase';
import { Collection } from '../enums';

// TEST NUMBERS / VERIFICATION CODES:

// +1 650-555-3434 / 654321
// tested user account exists with id
// tested user account doesn't exist and delete user

// +1 650-555-1234 / 123456
// tested user account exists with phone

// +1 650-555-4321 / 666666

// when getting user profile, first attempt via currentUser.uid and if that fails, attempt via phone number
export const getProfile = createAsyncThunk<Profile, User>('profile/getProfile', async (currentUser: User) => {
    if (!currentUser) {
        throw new ProfileError({ message: 'No user provided' });
    }

    const profileResponseCallback = async (querySnapshot: QuerySnapshot) => {
        const doc = querySnapshot.docs[0];

        const profile = { id: doc.id, ...doc.data() } as Profile;

        if (!profile) {
            throw new ProfileError({ message: 'No profile found' });
        }

        for (const key in profile) {
            let prop = profile[key as keyof Profile];

            if (prop instanceof Timestamp) {
                // TODO: test
                // firestore date field is nonserializeable
                prop = serializeDate(prop);
            }
        }

        return profile;
    };

    return getDocs(query(collection(db, Collection.Users), where('id_user', '==', currentUser.uid)))
        .then(profileResponseCallback)
        .catch(() => {
            // if profileResponseCallback threw an error
            // we were unable to get profile by uid
            // try getting profile by phone

            return getDocs(query(collection(db, Collection.Users), where('phone', '==', currentUser.phoneNumber)))
                .then(profileResponseCallback)
                .catch((error) => {
                    // if error here, profile doesn't exist
                    throw error;
                })
                .then(async (profile) => {
                    if (!profile.id_user) {
                        patchDoc({
                            collection: Collection.Users,
                            documentId: profile.id,
                            data: {
                                id_user: currentUser.uid
                            }
                        }).catch(({ message }) => toast.error(message));
                        return { ...profile, id_user: currentUser.uid } as Profile;
                    }

                    // delete the account we just created
                    await deleteUser(currentUser);

                    // if id_user is truthy, profile already exists but is attached to a different user
                    // don't overwrite user
                    throw new ProfileError({
                        message: `You must authenticate using your email address: ${profile.email}`,
                        isEmailRequired: true,
                        email: profile.email
                    });
                });
        });
});

const buildProfileFromPayload = (payload: Profile) => ({
    ...payload,
    displayName: `${payload.name_first} ${payload.name_last}`
});

type SliceState = {
    current: Profile | null;
    isLoading: boolean;
};

const initialState: SliceState = {
    current: null,
    isLoading: true
};

export const profile = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        profileLoaded: (state, action: PayloadAction<Profile>) => {
            const { payload } = action;

            state.isLoading = false;
            state.current = buildProfileFromPayload(payload);
        },
        clearCurrentProfile: (state) => {
            state.current = null;
            state.isLoading = false;
        }
    },
    extraReducers: (builder) => {
        // get profile by uid
        builder.addCase(getProfile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProfile.fulfilled, (state, { payload }) => {
            state.current = buildProfileFromPayload(payload);
            state.isLoading = false;
        });
        builder.addCase(getProfile.rejected, (state) => {
            state.isLoading = false;
            state.current = null;
        });
    }
});

export const { profileLoaded, clearCurrentProfile } = profile.actions;

export default profile.reducer;

import React from "react";
import { Form, Button } from "react-bootstrap";
import { useId } from "@reach/auto-id";

const AuthFormWrapper = ({
  children = null,
  buttonId,
  isDisabledButton = false,
  buttonLabel,
  onSubmit = () => {},
}) => {
  return (
    <Form onSubmit={onSubmit}>
      {children}
      <Button
        id={useId(buttonId)}
        className="w-100"
        type="submit"
        disabled={isDisabledButton}
      >
        {buttonLabel}
      </Button>
    </Form>
  );
};

export default AuthFormWrapper;

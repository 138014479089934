import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { Tabs, Tab } from "react-bootstrap";
import Input from "../form/Input";
import AuthFormWrapper from "./AuthFormWrapper";
import { auth } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toTitleCase } from "../../utils/strings";
import { login, logout } from "../../app/actions/auth";
import ConfirmPhone from "./ConfirmPhone";
import { useHandleAuthError, useNextPath } from "../../hooks/util";
import { useCloseSidebar } from "../../hooks/layout";

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const LoginForm = ({
  values,
  errors,
  onChange,
  onSubmit,
  isDisabledSubmit,
  buttonId,
  onSolveRecaptcha = () => {},
  type = "email",
}) => {
  const method = values.method;

  React.useEffect(() => {
    if (type === "phone") {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "submit-phone-login",
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.

            onSolveRecaptcha();
          },
        },
        auth
      );
    }

    // eslint-disable-next-line
  }, []);

  return (
    <AuthFormWrapper
      buttonId={buttonId}
      buttonLabel="Log In"
      isDisabledButton={isDisabledSubmit}
      onSubmit={onSubmit}
    >
      <Input
        field={method}
        label={toTitleCase(method)}
        value={values[method]}
        error={errors[method]}
        onChange={onChange}
      />
      {method === "email" && (
        <Input
          label="Password"
          field="password"
          value={values.password}
          error={errors.password}
          onChange={onChange}
        />
      )}
    </AuthFormWrapper>
  );
};

const Login = () => {
  const [confirmationResult, setConfirmationResult] = React.useState(null);
  const [isSolvedRecaptcha, setIsSolvedRecaptcha] = React.useState(false);

  const { handleNextPath } = useNextPath();
  const { handleAuthError } = useHandleAuthError();
  const handleCloseSidebar = useCloseSidebar();

  const {
    values,
    errors,
    isValid,
    dirty,
    isSubmitting,
    handleChange,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
      phone: "",
      password: "",
      method: "phone",
    },
    validationSchema: yup.object().shape({
      method: yup.string(),
      email: yup.string().when("method", {
        is: "phone",
        then: yup.string().nullable(),
        otherwise: yup
          .string()
          .email("Must be a valid email address.")
          .required("Email is required"),
      }),
      password: yup.string().when("method", {
        is: "phone",
        then: yup.string().nullable(),
        otherwise: yup.string().required("Password is required"),
      }),
      phone: yup.string().when("method", {
        is: "email",
        then: yup.string().nullable(),
        otherwise: yup
          .string()
          .required("Phone number is required")
          .test("phone", "Enter a valid 10 digit phone number", (value) => {
            if (!phoneRegex.test(value)) {
              return false;
            }
            return true;
          }),
      }),
    }),
    onSubmit: async (values) => {
      try {
        if (values.method === "phone") {
          const phoneNumber = values.phone.replace(phoneRegex, "+1 $1-$2-$3");

          // TODO: when there is an auth error on login, it doesn't allow a retry
          const resp = await signInWithPhoneNumber(
            auth,
            phoneNumber,
            window.recaptchaVerifier
          );
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          setConfirmationResult(resp);
        } else {
          await login(values.email, values.password);
          handleNextPath();
        }
      } catch (e) {
        if (values.method === "phone") {
          window.recaptchaVerifier.render().then((widgetId) => {
            // eslint-disable-next-line
            grecaptcha.reset(widgetId);
          });
        }
        handleAuthError(e, values.email);
      }
    },
  });

  const handleFailedPhoneConfirmation = async ({
    isEmailRequired = false,
    email = "",
  } = {}) => {
    if (isEmailRequired) {
      await resetForm();
      setFieldValue("email", email);
      setFieldValue("method", "email");
    }

    setIsSolvedRecaptcha(false);
    setConfirmationResult(null);
    logout();
  };

  const loginProps = {
    values,
    errors,
    onChange: handleChange,
    onSubmit: handleSubmit,
    isDisabledSubmit: isSubmitting || !isValid || !dirty,
  };

  const isReadyToConfirm =
    values.method === "phone" && !!confirmationResult && isSolvedRecaptcha;

  React.useEffect(() => {
    handleCloseSidebar();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isReadyToConfirm && (
        <Tabs
          id="login-tabs"
          activeKey={values.method}
          onSelect={(key) => setFieldValue("method", key)}
          className="mb-3"
        >
          <Tab eventKey="phone" title="By Phone">
            <LoginForm
              buttonId="submit-phone-login"
              type="phone"
              onSolveRecaptcha={() => setIsSolvedRecaptcha(true)}
              {...loginProps}
            />
          </Tab>
          <Tab eventKey="email" title="By Email">
            <LoginForm buttonId="submit-email-login" {...loginProps} />
            <div className="w-100 text-center mt-3">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </Tab>
        </Tabs>
      )}
      {isReadyToConfirm && (
        <ConfirmPhone
          confirmationResult={confirmationResult}
          onFail={handleFailedPhoneConfirmation}
        />
      )}
    </>
  );
};

export default Login;

import React, { ReactNode } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// TODO: Temporarily Disabled
// import UpcomingHunts from "./cards/UpcomingHunts";

import SignIn from './cards/SignIn';
import MembersPresent from './cards/MembersPresent';
import { Helmet } from 'react-helmet';
import config from '../../config';
import Solar from './cards/Solar';

const DashboardColumn = ({ children = null, lg = 6 }: { children: ReactNode; lg: number }) => (
    <Col xs={12} lg={lg} className="mb-4">
        {children}
    </Col>
);

const Dashboard = () => {
    return (
        <Container className="mt-5 dashboard">
            <Helmet>
                <title>{`${config.title} - Dashboard`}</title>
            </Helmet>
            <Row>
                <DashboardColumn lg={12}>
                    <SignIn />
                </DashboardColumn>
            </Row>

            <Row>
                <DashboardColumn lg={12}>
                    <Solar />
                </DashboardColumn>
            </Row>

            <Row>
                <DashboardColumn lg={12}>
                    <MembersPresent />
                </DashboardColumn>

                {/* TODO: Temporarily Disabled */}
                {/* <DashboardColumn>
          <Today />
        </DashboardColumn> */}
            </Row>

            {/* TODO: Temporarily Disabled */}
            {/* <Row>
        <DashboardColumn>
          <UpcomingHunts />
        </DashboardColumn>
      </Row> */}
        </Container>
    );
};

export default Dashboard;

import React from "react";
import classnames from "classnames";
import { Table, Button, Form, Row, Col } from "react-bootstrap";
import useSignIns from "../app/actions/signins";
import { formatDateString } from "../utils/dates";

const SignInTable = () => {
  const {
    data: signIns,
    isLoading,
    getNextPage,
    isEnd,
    setIsDescending,
  } = useSignIns();

  return (
    <div className="sign-in-table-wrapper">
      <Row className="mb-3">
        <Form.Group as={Col} xs={12} sm={2}>
          <Form.Label>Sort By</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              setIsDescending(e.target.value === "desc" ? true : false);
            }}
          >
            <option value="desc">Date (Most Recent)</option>
            <option value="asc">Date (Least Recent)</option>
          </Form.Control>
        </Form.Group>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Member</th>
            <th>Location</th>
            <th>Time In</th>
            <th>Time Out</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            signIns.map((signIn) => (
              <tr
                key={signIn.id}
                className={classnames({
                  "is-signed-in": !signIn.isSignedOut,
                })}
              >
                <td>{formatDateString(signIn.timestamp_start)}</td>
                <td>{signIn.name_member}</td>
                <td>{signIn.location}</td>
                <td>{formatDateString(signIn.timestamp_start, "hh:mm a")}</td>
                <td>
                  {!!signIn.timestamp_end &&
                    formatDateString(signIn.timestamp_end, "hh:mm a")}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        {!isEnd && <Button onClick={getNextPage}>Load More</Button>}
        {isEnd && <span>No more sign ins</span>}
      </div>
    </div>
  );
};

export default SignInTable;

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Route, Redirect } from 'react-router-dom';

interface Props {
    component: (props: any) => React.ReactElement;
    componentProps?: object;
    path: string;
    exact?: boolean;
}

const PrivateRoute = ({ component: Component, componentProps, ...rest }: Props) => {
    const { currentUser } = useAuth();
    return (
        <Route
            {...rest}
            render={(routerProps) => {
                return !!currentUser ? <Component {...componentProps} {...routerProps} /> : <Redirect to="/login" />;
            }}
        />
    );
};

export default PrivateRoute;

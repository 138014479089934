import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { Collection } from '../enums';
import { db } from '../firebase';
import { Club } from '../interfaces';

enum ClubTagTypes {
    Club = 'club'
}

export const clubApi = createApi({
    reducerPath: 'clubApi',
    baseQuery: fetchBaseQuery(),
    tagTypes: [ClubTagTypes.Club],
    endpoints: (builder) => ({
        club: builder.query<Club, void>({
            queryFn: async () => {
                try {
                    const clubId = process.env.REACT_APP_CLUB_ID;

                    if (!clubId || typeof clubId !== 'string') {
                        throw new Error('Club ID is not set');
                    }

                    const snapshot = await getDoc(doc(db, Collection.Clubs, clubId));
                    if (snapshot.exists()) {
                        const data = { id: snapshot.id, ...snapshot.data() } as Club;
                        return { data };
                    }

                    throw new Error('Club does not exist');
                } catch (e) {
                    const message = e instanceof Error ? e.message : 'Unknown error occurred';
                    console.error(message);
                    toast.error(`Error: ${message}`);
                    throw e;
                }
            },
            providesTags: () => [{ type: ClubTagTypes.Club }]
        }),
        updateClub: builder.mutation<Club, { club: Club; newData: {} }>({
            queryFn: async ({ club, newData }) => {
                const docRef = doc(db, Collection.Clubs, club.id);
                await updateDoc(docRef, newData);
                return { data: { ...club, ...newData } };
            },
            invalidatesTags: () => [{ type: ClubTagTypes.Club }]
        })
    })
});

export const { useClubQuery, useUpdateClubMutation } = clubApi;

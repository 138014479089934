import { db } from '../../firebase';
import { collection, addDoc, getDocs, query } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { serializeDate } from '../../utils/dates';
import { getSnapshotDocument } from '../../utils/firebase';
import { Collection } from '../../enums';

// TODO: this is not currently used anywhere
// TODO: keep around for later development
export const createUserProfile = async (uid) => {
    if (!!uid) {
        try {
            await addDoc(collection(db, Collection.Users), { id_user: uid });
        } catch (e) {
            toast.error('Unable to create user profile.');
        }
    }
};

export const getProfile = async (conditions = []) => {
    const snapshot = await getDocs(query(collection(db, Collection.Users), ...conditions));
    let profile;
    snapshot.forEach((doc) => {
        profile = getSnapshotDocument(doc);
        if (!!profile.dateOfBirth) {
            profile.dateOfBirth = serializeDate(profile.dateOfBirth);
        }
    });
    return profile;
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import Input from '../form/Input';
import AuthFormWrapper from './AuthFormWrapper';
import { useAuth } from '../../contexts/AuthContext';
import * as yup from 'yup';
import { updateEmail } from '../../app/actions/auth';
import { toast } from 'react-toastify';
import { patchDoc } from '../../utils/firebase';
import { profileLoaded } from '../../features/profile';
import { useHandleAuthError, useNextPath } from '../../hooks/util';
import { Collection } from '../../enums';

// TODO: thoroughly test this component
// TODO: test as phone user
const UpdateProfile = () => {
    const { currentUser } = useAuth();

    const { handleAuthError } = useHandleAuthError();

    const { handleNextPath } = useNextPath({ defaultPath: '/profile' });

    const profile = useSelector((state) => state.profile.current);
    const dispatch = useDispatch();

    const { values, initialValues, errors, isSubmitting, isValid, dirty, handleChange, handleSubmit } = useFormik({
        initialValues: {
            email: currentUser.email || profile.email || '',
            phone: currentUser.phoneNumber || profile.phone || '',
            is_admin: profile?.is_admin || false,
            name_first: profile.name_first || '',
            name_last: profile.name_last || '',
            dateOfBirth: profile.dateOfBirth || '',
            address: {
                line1: profile.address.line1 || '',
                line2: profile.address.line2 || '',
                city: profile.address.city || '',
                state: profile.address.state || '',
                zip: profile.address.zip || ''
            }
        },
        validationSchema: yup.object().shape({
            email: yup.string().email('Must be a valid email address.'),
            phone: yup.string(),
            is_admin: yup.boolean(),
            name_first: yup.string(),
            name_last: yup.string(),
            dateOfBirth: yup.string(),
            address: yup.object().shape({
                line1: yup.string(),
                line2: yup.string(),
                city: yup.string(),
                state: yup.string(),
                zip: yup.string()
            })
        }),
        onSubmit: async (values) => {
            try {
                // if user provider is phone, don't try to update email on user object
                if (initialValues.email !== values.email && currentUser.providerData[0] === 'password') {
                    await updateEmail(values.email);
                }

                const payload = { ...values, id: profile.id, id_user: profile.id_user };

                await patchDoc({
                    collection: Collection.Users,
                    documentId: profile.id,
                    data: payload
                });

                dispatch(profileLoaded(payload));
                toast.success(`Your profile has been updated.`);

                // REROUTE
                handleNextPath();
            } catch (e) {
                handleAuthError(e, values.email);
            }
        }
    });

    return (
        <AuthFormWrapper
            buttonId="submit-update"
            buttonLabel="Update Profile"
            isDisabledButton={isSubmitting || !isValid || !dirty}
            onSubmit={handleSubmit}
        >
            <Row>
                <Col xs={12} sm={6}>
                    <Input
                        label="Email"
                        field="email"
                        value={values.email}
                        error={errors.email}
                        onChange={handleChange}
                    />
                    <Input
                        label="Phone"
                        field="phone"
                        value={values.phone}
                        error={errors.phone}
                        onChange={handleChange}
                        isReadOnly
                    />

                    <Input
                        label="First Name"
                        field="name_first"
                        value={values.name_first}
                        error={errors.name_first}
                        onChange={handleChange}
                    />

                    <Input
                        label="Last Name"
                        field="name_last"
                        value={values.name_last}
                        error={errors.name_last}
                        onChange={handleChange}
                    />

                    <Input
                        label="Date of Birth"
                        field="dateOfBirth"
                        value={values.dateOfBirth}
                        error={errors.dateOfBirth}
                        onChange={handleChange}
                        isReadOnly
                    />

                    <Form.Group className="mb-4">
                        <Form.Check name="is_admin" checked={values.is_admin} label="Admin" disabled />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                    <Input
                        label="Address Line 1"
                        field="address.line1"
                        value={values.address.line1}
                        error={errors.address?.line1}
                        onChange={handleChange}
                    />

                    <Input
                        label="Address Line 2"
                        field="address.line2"
                        value={values.address.line2}
                        error={errors.address?.line2}
                        onChange={handleChange}
                    />

                    <Input
                        label="City"
                        field="address.city"
                        value={values.address.city}
                        error={errors.address?.city}
                        onChange={handleChange}
                    />

                    <Input
                        label="State"
                        field="address.state"
                        value={values.address.state}
                        error={errors.address?.state}
                        onChange={handleChange}
                    />

                    <Input
                        label="ZIP"
                        field="address.zip"
                        value={values.address.zip}
                        error={errors.address?.zip}
                        onChange={handleChange}
                    />
                </Col>
            </Row>
        </AuthFormWrapper>
    );
};

export default UpdateProfile;

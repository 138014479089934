import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const layout = createSlice({
    name: 'layout',
    initialState: {
        isToggledSidebar: true
    },
    reducers: {
        toggleSidebar: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;

            state.isToggledSidebar =
                // eslint-disable-next-line
                payload == undefined ? !state.isToggledSidebar : payload;
        }
    }
});

export const { toggleSidebar } = layout.actions;

export default layout.reducer;

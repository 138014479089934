import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

interface Props {
    text: string | null;
    path: string;
    variant: string | undefined;
    type: string | undefined;
    isDisabled?: boolean;
    handleClick: () => void;
}

const CardButton = ({
    text = null,
    path = '',
    variant = 'primary',
    type = 'link',
    isDisabled = false,
    handleClick = () => {}
}: Props) => {
    const commonClasses = 'w-100 mt-3';

    if (type === 'link') {
        return (
            <Link to={path} className={classnames(`btn btn-${variant}`, commonClasses)}>
                {text}
            </Link>
        );
    }
    if (type === 'button') {
        return (
            <Button variant={variant} className={commonClasses} onClick={handleClick} disabled={isDisabled}>
                {text}
            </Button>
        );
    }

    return null;
};

export default CardButton;

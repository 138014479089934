import React from "react";
import DashboardCard from "../DashboardCard";
import {
  getMembersPresent,
  useCurrentUserSignIn,
} from "../../../app/actions/signins";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

// TODO: react trying to update state on unmounted component
const SignIn = () => {
  const { isSignedIn, isLoading, handleSignIn, handleSignOut } =
    useCurrentUserSignIn();

  const { refetch } = useQuery("membersPresent", getMembersPresent);

  const heading = isSignedIn ? "Sign Out" : "Sign In";

  const locationSchema = yup.string();

  const toggleSignedInStatus = async (values) => {
    try {
      if (isSignedIn) {
        await handleSignOut();
      } else {
        await handleSignIn(values.location);
      }
      refetch();
    } catch (e) {
      console.error(e.message);
      toast.error(`Error: ${e.message}`);
    }
  };

  const { values, errors, resetForm, handleChange, handleSubmit } = useFormik({
    initialValues: { location: "" },
    validationSchema: yup.object().shape({
      location: isSignedIn ? locationSchema : locationSchema.required(),
    }),
    onSubmit: toggleSignedInStatus,
  });

  React.useEffect(() => {
    resetForm();
    // eslint-disable-next-line
  }, [isSignedIn]);

  return (
    <DashboardCard
      heading={heading}
      buttonText={heading}
      buttonVariant={isSignedIn ? "danger" : "success"}
      buttonType="button"
      buttonAction={handleSubmit}
      isButtonDisabled={isLoading}
    >
      {!isSignedIn && (
        <Form>
          <Form.Group>
            <Form.Label>Location</Form.Label>
            <Form.Control
              name="location"
              value={values.location}
              onChange={handleChange}
              isInvalid={errors.location}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.location}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </DashboardCard>
  );
};

export default SignIn;

import { configureStore } from '@reduxjs/toolkit';
import profile from '../features/profile';
import layout from '../features/layout';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { membersApi } from '../services/members';
import { clubApi } from '../services/club';

const store = configureStore({
    reducer: {
        profile,
        layout,
        [membersApi.reducerPath]: membersApi.reducer,
        [clubApi.reducerPath]: clubApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(membersApi.middleware, clubApi.middleware)
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export default store;

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import React from 'react';

export const useHandleAuthError = () => {
    const history = useHistory();

    const handleAuthError = (e, email) => {
        let error;
        if (e.code === 'auth/user-not-found') {
            error = `A user with email ${email} does not exist`;
        } else if (e.code === 'auth/requires-recent-login') {
            error = 'Please login to proceed';
            history.push('/login', { nextPath: '/update-profile' });
        } else if (e.code === 'auth/invalid-phone-number') {
            error =
                'Invalid phone number. Try entering your 10 digit phone number without any punctuation (e.g. dashes or parentheses)';
        } else if (!!e.code) {
            error = e.code;
        } else {
            error = e.message;
        }
        toast.error(error);
    };

    return { handleAuthError };
};

export const useNextPath = ({
    defaultPath = '/', // go to dashboard
    callback = null
} = {}) => {
    const history = useHistory();

    const handleNextPath = React.useCallback(() => {
        const nextPath = history.location.state?.nextPath;

        // REROUTE
        if (!!nextPath) {
            history.push(nextPath);
            return;
        }
        history.push(defaultPath);

        typeof callback === 'function' && callback();
    }, [callback, defaultPath, history]);

    return {
        handleNextPath
    };
};

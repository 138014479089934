import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaBars } from 'react-icons/fa';
import ProfileImage from '../../svg/profile.svg';
import { useAuth } from '../../contexts/AuthContext';
import { toggleSidebar } from '../../features/layout';
import { useLogout } from '../../hooks/firebase';
import { useClickOutside } from '../../hooks/layout';
import config from '../../config';
import classNames from 'classnames';

const Navbar = () => {
    const [isVisibleProfileDropdown, setIsVisibleProfileDropdown] = React.useState(false);

    const ref_profileDropdown = React.useRef();

    useClickOutside(ref_profileDropdown, [isVisibleProfileDropdown], () => setIsVisibleProfileDropdown(false));

    const displayName = useSelector((state) => state.profile.current?.displayName);
    const isToggledSidebar = useSelector((state) => state.layout.isToggledSidebar);
    const dispatch = useDispatch();

    const history = useHistory();

    const { currentUser } = useAuth();

    const handleLogout = useLogout();

    const handleProfileDropdown = () => {
        setIsVisibleProfileDropdown(!isVisibleProfileDropdown);
    };

    const handleDropdownLink = (callback = () => {}) => {
        setIsVisibleProfileDropdown(false);
        callback();
    };

    return (
        <nav
            className={classNames('navbar navbar-expand navbar-light bg-white topbar mb-3 static-top shadow', {
                unauthenticated: !currentUser
            })}
        >
            {/* <!-- Sidebar Toggle (Topbar) --> */}
            {!!currentUser && (
                <button
                    id="sidebarToggleTop"
                    className="btn btn-link d-md-none rounded-circle me-3"
                    onClick={() => dispatch(toggleSidebar())}
                >
                    <FaBars />
                </button>
            )}
            <div
                className={classNames('sidebar-brand-text mx-3 nav-title', {
                    toggled: !isToggledSidebar
                })}
            >
                <span>{config.title}</span>
            </div>
            {/* <!-- Topbar Navbar --> */}
            {!!currentUser && (
                <ul className="navbar-nav">
                    <li className="nav-item dropdown no-arrow">
                        <button className="nav-link dropdown-toggle" onClick={handleProfileDropdown}>
                            <span className="me-2 d-none d-lg-inline text-gray-600 small">{displayName}</span>
                            <img
                                className="img-profile rounded-circle"
                                src={ProfileImage}
                                alt="Profile avatar placeholder"
                            />
                        </button>

                        {/* <!-- Dropdown - User Information --> */}
                        <div
                            ref={ref_profileDropdown}
                            className={classnames('dropdown-menu dropdown-menu-right shadow animated--grow-in', {
                                show: isVisibleProfileDropdown
                            })}
                            aria-labelledby="userDropdown"
                        >
                            <button
                                className="dropdown-item"
                                onClick={() => handleDropdownLink(() => history.push('/profile'))}
                            >
                                Profile
                            </button>
                            <div className="dropdown-divider"></div>
                            <button className="dropdown-item" onClick={() => handleDropdownLink(handleLogout)}>
                                Logout
                            </button>
                        </div>
                    </li>
                </ul>
            )}
        </nav>
    );
};

export default Navbar;

import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './app/routes/Routes';
import * as Sentry from '@sentry/react';
import 'react-dates/initialize';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/lib/css/_datepicker.css';
import LayoutCenteredFullHeight from './components/layout/LayoutCenteredFullHeight';
import { history } from './index';

const ErrorBoundary = () => (
    <LayoutCenteredFullHeight>
        <h2>An error has occurred</h2>
    </LayoutCenteredFullHeight>
);

const fallback = <ErrorBoundary />;

const App = () => {
    return (
        <Sentry.ErrorBoundary fallback={fallback} showDialog>
            <Router history={history}>
                <AuthProvider>
                    <Routes />
                </AuthProvider>
            </Router>
            <ToastContainer />
        </Sentry.ErrorBoundary>
    );
};

export default App;

import React from 'react';
import { useDispatch } from 'react-redux';
import { getProfile } from '../features/profile';
import { auth } from '../firebase';

const AuthContext = React.createContext();

export const useAuth = () => {
    return React.useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = React.useState(); // user auth object
    const [isLoading, setIsLoading] = React.useState(true); // is loading user auth object

    const dispatch = useDispatch();

    React.useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setIsLoading(false);
        });

        return unsubscribe;
    }, []);

    React.useEffect(() => {
        if (!currentUser) {
            return;
        }

        dispatch(getProfile(currentUser));
    }, [dispatch, currentUser]);

    const value = {
        currentUser // user auth object
    };

    return <AuthContext.Provider value={value}>{!isLoading && children}</AuthContext.Provider>;
};

import React from 'react';
import { useSelector } from 'react-redux';
import { db } from '../../firebase';
import { toast } from 'react-toastify';
import { collection, query, orderBy, startAfter, limit, getDocs, where } from 'firebase/firestore';
import { getSnapshotDocument, patchDoc } from '../../utils/firebase';
import { serializeDate } from '../../utils/dates';
import config from '../../config';
import { useAuth } from '../../contexts/AuthContext';
import { createDoc } from './util';
import { getProfile } from './profile';
import { Collection } from '../../enums';

export const getMembersPresent = async () => {
    try {
        const snapshot = await getDocs(query(collection(db, Collection.SignIns), where('isSignedOut', '==', false)));

        const signIns = [];

        snapshot.forEach((doc) => {
            const signIn = getSnapshotDocument(doc);
            signIn.timestamp_start = serializeDate(signIn.timestamp_start);
            signIns.push(signIn);
        });

        const membersPresent = [];

        for (const signIn of signIns) {
            const profile = await getProfile([where('id_user', '==', signIn.id_user)]);
            membersPresent.push({ ...profile, location: signIn.location });
        }

        return membersPresent;
    } catch (e) {
        console.error(e.message);
        toast.error(`Error: ${e.message}`);
        throw e;
    }
};

export const useCurrentUserSignIn = () => {
    const { currentUser } = useAuth();
    const profile = useSelector((state) => state.profile.current);

    const [isSignedIn, setIsSignedIn] = React.useState(false); // TODO: store in redux so we don't have to load status every time
    const [isLoading, setIsLoading] = React.useState(true);

    const [currentSignInRecordId, setCurrentSignInRecordId] = React.useState(null);

    const getCurrentUserSignIn = async () => {
        setIsLoading(true);

        if (!!currentUser?.uid) {
            const snapshot = await getDocs(
                query(
                    collection(db, Collection.SignIns),
                    where('id_user', '==', currentUser.uid),
                    where('isSignedOut', '==', false)
                )
            );

            setCurrentSignInRecordId(snapshot.docs[0]?.id || null);
            setIsSignedIn(!!snapshot.docs.length);
        }

        setIsLoading(false);
    };

    const handleSignIn = async (location) => {
        try {
            const signInDoc = await createDoc(Collection.SignIns, {
                id_user: currentUser.uid,
                name_member: profile.displayName,
                timestamp_start: new Date(),
                isSignedOut: false,
                location
            });
            setCurrentSignInRecordId(signInDoc.id);
            setIsSignedIn(true);
        } catch (e) {
            throw e;
        }
    };

    const handleSignOut = async () => {
        try {
            await patchDoc({
                collection: Collection.SignIns,
                documentId: currentSignInRecordId,
                data: {
                    timestamp_end: new Date(),
                    isSignedOut: true
                }
            });
            setCurrentSignInRecordId(null);
            setIsSignedIn(false);
        } catch (e) {
            throw e;
        }
    };

    React.useEffect(() => {
        getCurrentUserSignIn();

        // eslint-disable-next-line
    }, [currentUser]);

    return { isSignedIn, handleSignIn, handleSignOut, isLoading };
};

const useSignIns = () => {
    const [signIns, setSignIns] = React.useState([]);
    const [lastVisible, setLastVisible] = React.useState();
    const [isEnd, setIsEnd] = React.useState(false);
    const [isDescending, setIsDescending] = React.useState(true);

    const TIMESTAMP_START = 'timestamp_start';

    const processSnapshot = (snapshot, page) => {
        const temp = [];

        if (!snapshot.docs.length) {
            setIsEnd(true);
            return;
        }

        snapshot.forEach((doc) => {
            const signin = getSnapshotDocument(doc);
            signin.timestamp_start = serializeDate(signin.timestamp_start);
            if (!!signin.timestamp_end) {
                signin.timestamp_end = serializeDate(signin.timestamp_end);
            }
            temp.push(signin);
        });

        const prevSignIns = page === 'first' ? [] : signIns;

        setSignIns([...prevSignIns, ...temp]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    };

    const getOrderBy = () => {
        if (!isDescending) {
            return orderBy(TIMESTAMP_START);
        } else {
            return orderBy(TIMESTAMP_START, 'desc');
        }
    };

    const getFirst = () => {
        return getDocs(query(collection(db, Collection.SignIns), getOrderBy(), limit(config.paginationLimit)));
    };

    const getNext = () => {
        return getDocs(
            query(
                collection(db, Collection.SignIns),
                getOrderBy(),
                startAfter(lastVisible),
                limit(config.paginationLimit)
            )
        );
    };

    const getSignIns = async (page = 'first') => {
        try {
            let snapshot;
            if (page === 'first') {
                snapshot = await getFirst();
            } else if (page === 'next') {
                snapshot = await getNext();
            }
            processSnapshot(snapshot, page);
        } catch (e) {
            console.error(e.message);
            toast.error(`Error: ${e.message}`);
            throw e;
        }
    };

    // Query the first page of docs
    React.useEffect(() => {
        getSignIns();
        setIsEnd(false);

        // eslint-disable-next-line
    }, [isDescending]);

    return {
        data: signIns,
        isEnd,
        getNextPage: () => getSignIns('next'),
        setIsDescending
    };
};

export default useSignIns;

import React from 'react';
import DescriptionList from './DescriptionList';
import { useAuth } from '../contexts/AuthContext';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import config from '../config';
import Header from './Header';
import CardButton from './CardButton';

const getValue = (value) => value;

const profileParamDictionary = [
    {
        key: 'is_admin',
        label: 'Admin',
        getValue: (is_admin) => String(!!is_admin),
        order: 1
    },
    {
        key: 'name_first',
        label: 'First Name',
        getValue,
        order: 2
    },
    {
        key: 'name_last',
        label: 'Last Name',
        getValue,
        order: 3
    },
    {
        key: 'dateOfBirth',
        label: 'Date of Birth',
        getValue,
        order: 4
    },
    {
        key: 'address',
        label: 'Address',
        getValue: (address) => (
            <span style={{ display: 'inline-block' }}>
                {address.line1}
                {!!address.line2 && (
                    <>
                        <br />
                        {address.line2}
                    </>
                )}
                <br />
                {address.city}, {address.state} {address.zip}
            </span>
        ),
        order: 5
    }
];

const Profile = () => {
    const { currentUser } = useAuth();

    const profile = useSelector((state) => state.profile.current);

    const pairs = React.useMemo(() => {
        const {
            providerId, // will be one of 'phone' or 'password'
            phoneNumber: userPhone, // will be null if providerId is 'password
            email: userEmail // will be null if providerId is 'email' (unless email attached to user later)
        } = currentUser.providerData[0];

        const { email: profileEmail, phone: profilePhone } = profile;

        const pairs = [];

        if (providerId === 'password' || profileEmail) {
            pairs.push(['Email', userEmail || profileEmail]);
        }

        if (providerId === 'phone' || profilePhone) {
            pairs.push(['Phone', userPhone || profilePhone]);
        }

        const temp = [];
        Object.keys(profile).forEach((key) => {
            const param = profileParamDictionary.find((param) => param.key === key);
            if (!!param) {
                temp.push([param.order, param.label, param.getValue(profile[key])]);
            }
        });

        temp.sort((a, b) => a[0] - b[0]);
        temp.forEach((pair) => pair.shift());

        pairs.push(...temp);

        return pairs;
    }, [currentUser, profile]);

    return (
        <Container className="mt-5">
            <Helmet>
                <title>{`${config.title} - Profile`}</title>
            </Helmet>
            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <Header heading={'Profile'} />
                            <div>
                                <DescriptionList labelValuePairs={pairs} />
                            </div>
                            <CardButton text="Update Profile" path="/update-profile" />

                            {/* TODO: re-enable */}
                            {/* <CardButton
                text="Change Password"
                path="/change-password"
                isDisabled={
                  currentUser.providerData[0].providerId !== "password"
                }
              /> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;

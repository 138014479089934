import { useFormik } from 'formik';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useClubQuery, useUpdateClubMutation } from '../services/club';
import DotSpinner from './DotSpinner';
import Input from './form/Input';

const ManageProperty = () => {
    const { data: club, isLoading, isError } = useClubQuery();

    const { latitude, longitude } = club || {};

    const [updateClub] = useUpdateClubMutation();

    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: {
            latitude,
            longitude
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            if (!club) {
                throw new Error('Club not found');
            }
            updateClub({ club, newData: { latitude: values.latitude, longitude: values.longitude } })
                .unwrap()
                .then(() => toast.success('Club updated'))
                .catch(() => toast.error('Failed to update club'));
        }
    });

    return (
        <div className="mb-5">
            <h2 className="mb-4">Manage Property</h2>
            <p>These coordinates affect your property map and solar events chart.</p>
            {isLoading && <DotSpinner />}
            {!isLoading && (
                <>
                    {isError && <p>Oops. There was an error loading property details.</p>}
                    {!isError && (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Input
                                        field="latitude"
                                        label="Latitude"
                                        type="number"
                                        value={values.latitude}
                                        error={errors.latitude}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Input
                                        field="longitude"
                                        label="Longitude"
                                        type="number"
                                        value={values.longitude}
                                        error={errors.longitude}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Button type="submit">Save</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </>
            )}
        </div>
    );
};

export default ManageProperty;

import React from 'react';
import classnames from 'classnames';
import { Card } from 'react-bootstrap';
import Header from '../Header';
import DotSpinner from '../DotSpinner';
import CardButton from '../CardButton';

interface Props {
    heading: string;
    children: React.ReactNode;
    isLoading?: boolean;
    isButtonDisabled?: boolean;
    buttonText?: string;
    buttonPathTo?: string;
    innerContentClass?: string;
    buttonVariant?: string;
    buttonType?: string;
    buttonAction?: () => void;
}

const DashboardCard = ({
    heading = '',
    children = null,
    isLoading = false,
    isButtonDisabled = false,
    buttonText = '',
    buttonPathTo = '',
    innerContentClass = '',
    buttonVariant = '',
    buttonType = '',
    buttonAction = () => {}
}: Props) => (
    <Card>
        <Card.Body>
            <Header heading={heading} />
            <div className={classnames('dashboard-inner-content', innerContentClass)}>
                {isLoading && <DotSpinner />}
                {!isLoading && children}
            </div>
            {!!buttonText && !isButtonDisabled && (
                <CardButton
                    text={buttonText}
                    path={buttonPathTo}
                    variant={buttonVariant || undefined}
                    type={buttonType || undefined}
                    handleClick={buttonAction}
                />
            )}
        </Card.Body>
    </Card>
);

export default DashboardCard;

import { useFormik } from "formik";
import React from "react";
import Input from "../form/Input";
import AuthFormWrapper from "./AuthFormWrapper";
import * as yup from "yup";
import { toast } from "react-toastify";
import { resetPassword } from "../../app/actions/auth";
import { useHandleAuthError, useNextPath } from "../../hooks/util";

// TODO: thoroughly test this component
const ForgotPassword = () => {
  const { handleNextPath } = useNextPath({
    defaultPath: "/login",
    callback: () =>
      toast.success("Check your email for password recovery instructions"),
  });

  const { handleError } = useHandleAuthError();

  const { values, errors, isSubmitting, isValid, dirty, handleChange } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: yup.object().shape({
        email: yup
          .string()
          .email("Must be a valid email address.")
          .required("Email is required"),
      }),
      onSubmit: async (values) => {
        try {
          await resetPassword(values.email);
          toast.success(
            `You will receive an email with a link to reset your password.`
          );

          handleNextPath();
        } catch (e) {
          handleError(e, values.email);
        }
      },
    });

  return (
    <AuthFormWrapper
      buttonId="submit-forgot-password"
      buttonLabel="Reset Password"
      isDisabledButton={isSubmitting || !isValid || !dirty}
    >
      <Input
        field="email"
        value={values.email}
        error={errors.email}
        onChange={handleChange}
      />
    </AuthFormWrapper>
  );
};

export default ForgotPassword;

import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Dashboard from '../../components/dashboard/Dashboard';
import Auth from '../../components/auth/Auth';
import { useSelector } from 'react-redux';
import LayoutCenteredFullHeight from '../../components/layout/LayoutCenteredFullHeight';
import DotSpinner from '../../components/DotSpinner';
import { useAuth } from '../../contexts/AuthContext';
import { Alert } from 'react-bootstrap';
import { logout } from '../actions/auth';
import Layout from '../../components/layout/Layout';
import Profile from '../../components/Profile';
import SignIns from '../../pages/SignInTable';
import ErrorPage from '../../pages/ErrorPage';
import Admin from '../../pages/Admin';
import { RootState } from '../store';

const ProfileRoutes = () => {
    const { currentUser } = useAuth();

    const { isLoading, current: profile } = useSelector((state: RootState) => state.profile);

    if (currentUser && isLoading) {
        return (
            <LayoutCenteredFullHeight className="align-items-center">
                <DotSpinner />
            </LayoutCenteredFullHeight>
        );
    }

    if (currentUser && !profile) {
        return (
            <LayoutCenteredFullHeight>
                <Alert variant={'danger'}>Unable to load user.</Alert>
            </LayoutCenteredFullHeight>
        );
    }

    return (
        <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute path="/profile" component={Profile}></PrivateRoute>
            <PrivateRoute path="/update-profile" component={Auth} componentProps={{ type: 'update' }} />

            {/* TODO: reenable */}
            {/* <PrivateRoute
        path="/change-password"
        component={Auth}
        componentProps={{ type: "change-password" }}
      />
      <PrivateRoute path="/register-hunt" component={RegisterHunt} />
      <PrivateRoute path="/map" component={Map} /> */}

            <PrivateRoute path="/signins" component={SignIns} />
            {profile?.is_admin && <PrivateRoute path="/admin" component={Admin} />}
            <Route path="/forgot-password" render={(props) => <Auth type="forgot-password" {...props} />} />
            <PrivateRoute path="*" component={ErrorPage} />
        </Switch>
    );
};

const Routes = () => (
    <Layout>
        <Switch>
            <Route
                path="/logout"
                render={() => {
                    logout();
                    return <Redirect to="/login" />;
                }}
            />
            <Route path="/login" render={(props) => <Auth type="login" {...props} />} />
            <ProfileRoutes />
        </Switch>
    </Layout>
);

export default Routes;

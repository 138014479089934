import { Form } from 'react-bootstrap';

interface InputProps {
    label: string;
    field: string;
    value: any;
    type: string;
    error: string | null | undefined;
    isReadOnly?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({
    label = '',
    field = '',
    value = '',
    type = 'text',
    error = null,
    isReadOnly = false,
    onChange = () => {}
}: InputProps) => (
    <Form.Group id={field} className="mb-4">
        <Form.Label>{label}</Form.Label>
        <Form.Control
            name={field}
            type={type}
            value={value}
            isInvalid={!!error}
            onChange={onChange}
            readOnly={isReadOnly}
        />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
);

export default Input;

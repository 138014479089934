import React from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../contexts/AuthContext';
import DotSpinner from '../DotSpinner';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AuthFormWrapper from './AuthFormWrapper';
import Input from '../form/Input';
import { useHandleAuthError, useNextPath } from '../../hooks/util';

const ConfirmPhone = ({ confirmationResult, onFail = () => {} }) => {
    const { currentUser } = useAuth();
    const { handleNextPath } = useNextPath();
    const { handleAuthError } = useHandleAuthError();

    const profile = useSelector((state) => state.profile.current);

    const { values, dirty, errors, isValid, handleChange, handleSubmit } = useFormik({
        initialValues: {
            confirmationCode: ''
        },
        validationSchema: yup.object().shape({
            confirmationCode: yup
                .string()
                .length(6, 'Enter 6 digit code')
                .matches(/[0-9]+/gi, 'Enter only numeric values')
                .required('Enter the confirmation code')
        }),
        onSubmit: async (values) => {
            try {
                // if this succeeds, then currentUser is going to change
                // listen in effect for currentUser to change
                await confirmationResult.confirm(values.confirmationCode);
            } catch (e) {
                handleAuthError(e);
            }
        }
    });

    React.useEffect(() => {
        if (!!profile) {
            handleNextPath();
            return;
        }
    }, [profile, handleNextPath]);

    if (!!currentUser && !profile) {
        return <DotSpinner />;
    }

    return (
        <AuthFormWrapper
            buttonId="submit-confirmation-code"
            buttonLabel="Confirm Phone Number"
            isDisabledButton={!dirty || !isValid}
            onSubmit={handleSubmit}
        >
            <Input
                label="Enter the confirmation code sent to your phone"
                field="confirmationCode"
                value={values.confirmationCode}
                error={errors.confirmationCode}
                onChange={handleChange}
            />
        </AuthFormWrapper>
    );
};

export default ConfirmPhone;

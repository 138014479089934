import { Button, Table } from 'react-bootstrap';
import { useMembersQuery, useUpdateMemberMutation } from '../services/members';
import React from 'react';
import ConfirmationModal from './modals/ConfirmationModal';
import { Profile } from '../interfaces';
import { toast } from 'react-toastify';

const MemberList = () => {
    const { data: members, isLoading } = useMembersQuery();

    const [isVisibleAdminStatusConfirmationModal, setIsVisibleAdminStatusConfirmationModal] = React.useState(false);
    const [selectedMember, setSelectedMember] = React.useState<Profile | null>(null);

    const [updateMember] = useUpdateMemberMutation();

    const toggleSelectedMemberAdminStatus = async () => {
        if (!selectedMember) {
            return;
        }

        updateMember({ profile: selectedMember, newData: { is_admin: !selectedMember.is_admin } })
            .unwrap()
            .then(() => {
                toast.success('Member admin status updated');
                setIsVisibleAdminStatusConfirmationModal(false);
            })
            .catch(() => toast.error('Failed to update member admin status'));
    };

    return (
        <div className="mb-5">
            <h2 className="mb-4">Manage Users</h2>
            <Table striped bordered hover className="mb-0">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Is Admin</th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading &&
                        members?.map((member: Profile) => {
                            const { id, name_first, name_last, email, phone, is_admin } = member;
                            return (
                                <tr key={id}>
                                    <td>{name_first}</td>
                                    <td>{name_last}</td>
                                    <td>{email}</td>
                                    <td>{phone}</td>

                                    <td>
                                        {
                                            <Button
                                                className="admin-status-button"
                                                variant={is_admin ? 'secondary' : 'danger'}
                                                onClick={() => {
                                                    setSelectedMember(member);
                                                    setIsVisibleAdminStatusConfirmationModal(true);
                                                }}
                                            >
                                                {is_admin && 'Yes'}
                                                {!is_admin && 'No'}
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
            <ConfirmationModal
                isVisible={isVisibleAdminStatusConfirmationModal}
                confirmationPrompt={
                    selectedMember?.is_admin
                        ? `Are you sure you want to remove ${selectedMember.name_first} ${selectedMember.name_last}'s admin privileges?`
                        : `Are you sure you want to give ${selectedMember?.name_first} ${selectedMember?.name_last} admin privileges?`
                }
                confirmButtonLabel={selectedMember?.is_admin ? 'Remove Admin' : 'Make Admin'}
                onClose={() => setIsVisibleAdminStatusConfirmationModal(false)}
                onConfirm={() => toggleSelectedMemberAdminStatus()}
            />
        </div>
    );
};

export default MemberList;

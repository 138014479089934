import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import Header from "../Header";
import LayoutCenteredFullHeight from "../layout/LayoutCenteredFullHeight";
import { Helmet } from "react-helmet";
import config from "../../config";
import Login from "./Login";
import UpdateProfile from "./UpdateProfile";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import { useAuth } from "../../contexts/AuthContext";

const Auth = ({ type = "login" }) => {
  const history = useHistory();
  const { currentUser } = useAuth();

  React.useEffect(() => {
    if (!!currentUser && type === "login") {
      history.replace("/");
    }

    // eslint-disable-next-line
  }, [currentUser]);

  const label = (
    <>
      {type === "forgot-password" && "Reset Password"}
      {type === "login" && "Log In"}
      {type === "update" && "Update Profile"}
      {type === "change-password" && "Change Password"}
    </>
  );

  let linkTo, title;
  if (type === "forgot-password") {
    linkTo = "/login";
    title = "Forgot Password";
  } else if (type === "login") {
    title = "Log In";
  } else if (type === "update" || type === "change-password") {
    linkTo = "/profile";
    if (type === "update") {
      title = "Update Profile";
    } else if (type === "change-password") {
      title = "Change Password";
    }
  }

  return (
    <LayoutCenteredFullHeight>
      <div className="w-100" style={{ maxWidth: type !== "update" && "400px" }}>
        <Helmet>
          <title>{`${config.title} - ${title}`}</title>
        </Helmet>
        <Card>
          <Card.Body>
            <Header heading={label} />
            {type === "login" && <Login />}
            {type === "update" && <UpdateProfile />}
            {type === "forgot-password" && <ForgotPassword />}
            {type === "change-password" && <ChangePassword />}
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          {(type === "forgot-password" || type === "change-password") && (
            <span>Back to </span>
          )}
          {type !== "login" && (
            <Link to={linkTo}>
              <>
                {type === "forgot-password" && "Log In"}
                {type === "update" && "Cancel"}
                {type === "change-password" && "Profile"}
              </>
            </Link>
          )}
        </div>
      </div>
    </LayoutCenteredFullHeight>
  );
};

export default Auth;

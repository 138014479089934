import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { collection, doc, getDocs, query, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { Collection } from '../enums';
import { db } from '../firebase';
import { Profile } from '../interfaces';
import { getSnapshotDocument } from '../utils/firebase';

enum TagTypes {
    Members = 'members'
}

export const membersApi = createApi({
    reducerPath: 'membersApi',
    baseQuery: fetchBaseQuery(),
    tagTypes: [TagTypes.Members],
    endpoints: (builder) => ({
        members: builder.query<Profile[], void>({
            queryFn: async () => {
                try {
                    const snapshot = await getDocs(query(collection(db, Collection.Users)));

                    const users: Profile[] = [];

                    snapshot.forEach((doc) => users.push(getSnapshotDocument(doc)));

                    return { data: users };
                } catch (e) {
                    const message = e instanceof Error ? e.message : 'Unknown error occurred';
                    console.error(message);
                    toast.error(`Error: ${message}`);
                    throw e;
                }
            },
            providesTags: () => [TagTypes.Members]
        }),
        updateMember: builder.mutation<Profile, { profile: Profile; newData: {} }>({
            queryFn: async ({ profile, newData }) => {
                const docRef = doc(db, Collection.Users, profile.id);
                await updateDoc(docRef, newData);
                return { data: { ...profile, ...newData } };
            },
            invalidatesTags: () => [TagTypes.Members]
        })
    })
});

export const { useMembersQuery, useUpdateMemberMutation } = membersApi;

import React from 'react';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';

interface Props {
    children: React.ReactNode;
    className?: string;
}

const LayoutCenteredFullHeight = ({ children = null, className }: Props) => (
    <Container className={classnames('d-flex justify-content-center h-100', className)}>{children}</Container>
);

export default LayoutCenteredFullHeight;

import { Timestamp } from "firebase/firestore";
import moment from "moment";

export const getMidnight = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const formatDateString = (
  dateString = "",
  formatString = "MM/DD/YYYY"
) => {
  return moment(new Date(dateString)).format(formatString);
};

export const serializeDate = (date: Timestamp): Timestamp | string => {
  let temp;
  try {
    // sometimes date is not convertable to a date
    temp = date.toDate().toString();
  } catch (error) {
    console.error(error instanceof Error ? error.message : 'Unknown error occurred');
  }
  return temp || date;
};

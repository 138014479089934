import { Button, Modal } from 'react-bootstrap';

const ConfirmationModal = ({
    confirmationPrompt = '',
    confirmButtonLabel = 'Confirm',
    isVisible = false,
    onClose = () => {},
    onConfirm = () => {}
}) => (
    <Modal show={isVisible}>
        <Modal.Body>
            <p>{confirmationPrompt}</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
                Close
            </Button>
            <Button variant="danger" onClick={onConfirm}>
                {confirmButtonLabel}
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ConfirmationModal;

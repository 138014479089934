import ManageProperty from '../components/ManageProperty';
import MemberList from '../components/MemberList';

const Admin = () => (
    <div className="container">
        <MemberList />

        <ManageProperty />
    </div>
);

export default Admin;

import React from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import AuthFormWrapper from "./AuthFormWrapper";
import * as yup from "yup";
import { updatePassword } from "../../app/actions/auth";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router";
import { useHandleAuthError, useNextPath } from "../../hooks/util";

// TODO: user should only be able to change password if their auth type is email
// TODO: thoroughly test this component
const ChangePassword = () => {
  const { handleAuthError } = useHandleAuthError();
  const { handleNextPath } = useNextPath();
  const { currentUser } = useAuth();
  const history = useHistory();

  const {
    values,
    errors,
    isSubmitting,
    isValid,
    dirty,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object().shape({
      password: yup.string().required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      try {
        await updatePassword(values.password);
        toast.success("Your password has been updated");

        // REROUTE
        handleNextPath();
      } catch (e) {
        handleAuthError(e, values.email);
      }
    },
  });

  if (currentUser.providerData[0].providerId !== "password") {
    history.replace("/");
    return;
  }

  const passwordPlaceholder = "Leave blank to keep same password";

  return (
    <AuthFormWrapper
      buttonId="submit-change-password"
      buttonLabel="Change Password"
      isDisabledButton={isSubmitting || !isValid || !dirty}
      onSubmit={handleSubmit}
    >
      {/* TODO: add field current password */}

      {/* PASSWORD */}
      <Form.Group id="password" className="mb-4">
        <Form.Label>Password</Form.Label>
        <Form.Control
          name="password"
          type="password"
          value={values.password}
          isInvalid={errors.password}
          onChange={handleChange}
          placeholder={passwordPlaceholder}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password}
        </Form.Control.Feedback>
      </Form.Group>

      {/* CONFIRM PASSWORD */}
      <Form.Group id="password-confirm" className="mb-4">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          name="confirmPassword"
          type="password"
          value={values.confirmPassword}
          isInvalid={errors.confirmPassword}
          onChange={handleChange}
          placeholder={passwordPlaceholder}
        />
        <Form.Control.Feedback type="invalid">
          {errors.confirmPassword}
        </Form.Control.Feedback>
      </Form.Group>
    </AuthFormWrapper>
  );
};

export default ChangePassword;

import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

// ICONS
import { FiLogOut } from 'react-icons/fi';
import { FaTachometerAlt, FaMap } from 'react-icons/fa';
import { RiAdminLine } from 'react-icons/ri';
import { useLogout } from '../../hooks/firebase';
import { useCloseSidebar } from '../../hooks/layout';
import config from '../../config';
import { RootState } from '../../app/store';

const Sidebar = () => {
    const isToggledSidebar = useSelector((state: RootState) => state.layout.isToggledSidebar);
    const profile = useSelector((state: RootState) => state.profile.current);

    const handleLogout = useLogout();
    const handleCloseSidebar = useCloseSidebar();

    const history = useHistory();
    const pathname = history.location.pathname;

    const { currentUser: isAuthenticated } = useAuth();

    return (
        <ul
            className={classnames('navbar-nav bg-gradient-brand sidebar sidebar-dark accordion', {
                toggled: isToggledSidebar
            })}
            id="accordionSidebar"
        >
            {/* <!-- Sidebar - Brand --> */}
            <Link className="sidebar-brand d-flex align-items-center justify-content-center my-3" to="/">
                <div className="sidebar-brand-text mx-3">
                    <span>{config.title}</span>
                </div>
            </Link>

            {isAuthenticated && (
                <>
                    {/* <!-- Divider --> */}
                    <hr className="sidebar-divider my-0" />

                    {/* <!-- Nav Item - Dashboard --> */}
                    <li className={classnames('nav-item', { active: pathname === '/' })}>
                        <Link className="nav-link" to="/" onClick={handleCloseSidebar}>
                            <FaTachometerAlt />
                            <span>Dashboard</span>
                        </Link>
                    </li>

                    {/* <!-- Divider --> */}
                    <hr className="sidebar-divider" />

                    {/* TODO: reenable */}
                    {/* <!-- Nav Item - Directory --> */}
                    {/* <li className="nav-item">
            <Link className="nav-link" to="/">
              <RiContactsBookFill />
              <span>Directory</span>
            </Link>
          </li> */}

                    {/* TODO: re-enable */}
                    {/* <!-- Nav Item - Property Map --> */}
                    {/* <li
            className={classnames("nav-item", { active: pathname === "/map" })}
          >
            <Link className="nav-link" to="/map">
              <FaMap />
              <span>Property Map</span>
            </Link>
          </li> */}

                    {/* <!-- Nav Item - Sign In Sheet --> */}
                    <li
                        className={classnames('nav-item', {
                            active: pathname === '/signins'
                        })}
                    >
                        <Link className="nav-link" to="/signins" onClick={handleCloseSidebar}>
                            <FaMap />
                            <span>Sign In Sheet</span>
                        </Link>
                    </li>

                    <hr className="sidebar-divider d-none d-md-block" />

                    {!!profile?.is_admin && (
                        <>
                            {/* <!-- Nav Item - Admin Area --> */}
                            <li
                                className={classnames('nav-item', {
                                    active: pathname === '/signins'
                                })}
                            >
                                <Link className="nav-link" to="/admin" onClick={handleCloseSidebar}>
                                    <RiAdminLine />
                                    <span>Admin Console</span>
                                </Link>
                            </li>

                            <hr className="sidebar-divider d-none d-md-block" />
                        </>
                    )}

                    {/* <!-- Logout Button --> */}
                    <div className="text-center d-md-inline">
                        <button className="rounded-circle border-0" title="Logout" onClick={handleLogout} id="logout">
                            <FiLogOut stroke="white" style={{ position: 'relative', top: '-2px', left: '1px' }} />
                        </button>
                    </div>
                </>
            )}
        </ul>
    );
};

export default Sidebar;

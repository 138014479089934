import React from 'react';
import DashboardCard from '../DashboardCard';
import SunCalc from 'suncalc';
import { useClubQuery } from '../../../services/club';
import {
    CategoryScale,
    Chart,
    ChartTypeRegistry,
    TimeScale,
    LineController,
    LineElement,
    PointElement,
    ScaleTypeRegistry,
    LinearScale,
    InteractionMode,
    CoreInteractionOptions,
    Tooltip,
    TooltipItem
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment, { Moment } from 'moment';
import { camelCaseToTitleCase } from '../../../utils/strings';

Chart.register(LineController, CategoryScale, TimeScale, LinearScale, PointElement, LineElement, Tooltip);

const chartType: keyof ChartTypeRegistry = 'line';
const scaleType: keyof ScaleTypeRegistry = 'time';
const interactionMode: InteractionMode = 'nearest';
const interactionAxis: CoreInteractionOptions['axis'] = 'xy';

interface DataPoint {
    x: Moment;
    y: number;
    key: string;
}

const Solar = () => {
    const { data: club } = useClubQuery();

    const chartConfig = React.useMemo(() => {
        if (!club?.latitude || !club?.longitude) {
            return;
        }

        const times = SunCalc.getTimes(new Date(), club.latitude, club.longitude);

        const orderedTimes = Object.keys(times)
            .map((key) => ({ key, value: times[key as keyof SunCalc.GetTimesResult] }))
            .sort((a, b) => a.value.getTime() - b.value.getTime());

        orderedTimes.unshift({ key: 'startOfDay', value: moment().startOf('day').toDate() });
        orderedTimes.push({ key: 'endOfDay', value: moment().endOf('day').toDate() });

        return {
            type: chartType,
            data: {
                labels: orderedTimes.map((time) => moment(time.value).format('HH:mm')),
                datasets: [
                    {
                        backgroundColor: '#1cc88a',
                        borderColor: '#1cc88a',
                        data: orderedTimes.map(
                            (time): DataPoint => ({
                                x: moment(time.value),
                                y: SunCalc.getPosition(time.value, club.latitude, club.longitude).altitude,
                                key: time.key
                            })
                        )
                    }
                ]
            },
            options: {
                scales: {
                    x: {
                        type: scaleType,
                        time: {
                            tooltipFormat: 'h:mm a'
                        },
                        title: {
                            display: true,
                            text: 'Time'
                        },
                        min: moment().startOf('day').toString()
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Altitude'
                        }
                    }
                },
                elements: {
                    line: {
                        tension: 0.4
                    }
                },
                plugins: {
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: (context: TooltipItem<typeof chartType>) => {
                                const rawContext = context.raw as DataPoint; // TODO: research if this is right

                                return camelCaseToTitleCase(rawContext.key);
                            }
                        }
                    }
                },
                interaction: {
                    intersect: false,
                    mode: interactionMode,
                    axis: interactionAxis
                }
            }
        };
    }, [club?.latitude, club?.longitude]);

    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const solarChart = React.useRef<Chart<typeof chartType, { x: Moment; y: number }[]> | null>(null);

    React.useEffect(() => {
        if (!canvasRef.current || !chartConfig) {
            return;
        }

        solarChart.current = new Chart(canvasRef.current, chartConfig);

        return () => {
            solarChart.current?.destroy();
        };
    }, [chartConfig]);

    return (
        <DashboardCard heading={`Solar Events - ${moment().format('MMM D, YYYY')}`}>
            <div className="w-100 h-100 container">
                <canvas ref={canvasRef} id="solarChart"></canvas>
            </div>
        </DashboardCard>
    );
};

export default Solar;

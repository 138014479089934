import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { logout } from '../app/actions/auth';
import { clearCurrentProfile } from '../features/profile';
import { useCloseSidebar } from './layout';

export const useLogout = () => {
    const handleCloseSidebar = useCloseSidebar();
    const history = useHistory();
    const dispatch = useDispatch();

    return async () => {
        handleCloseSidebar();
        try {
            await logout();
            dispatch(clearCurrentProfile());
            history.push('/login');
        } catch (e) {
            toast.error('Unable to logout');
        }
    };
};

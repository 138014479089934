import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

const Layout = ({ children }) => (
  <div id="wrapper">
    <Sidebar />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" className="d-flex flex-column">
      {/* <!-- Topbar --> */}
      <Navbar />

      {/* <!-- Main Content --> */}
      <div className="main-content">{children}</div>
    </div>
  </div>
);

export default Layout;

import React from "react";
import classnames from "classnames";
import { useQuery } from "react-query";
import { Alert, Table } from "react-bootstrap";
import DashboardCard from "../DashboardCard";
import { getMembersPresent } from "../../../app/actions/signins";

const MembersPresent = () => {
  // TODO: replace with RTK query
  const { data: membersPresent, isLoading } = useQuery(
    "membersPresent",
    getMembersPresent
  );

  return (
    <>
      <DashboardCard
        heading="Members Present"
        isLoading={isLoading}
        innerContentClass={classnames({ border: !!membersPresent?.length })}
      >
        {!membersPresent?.length && (
          <Alert variant="info">
            There are no members currently present at the hunting location.
          </Alert>
        )}
        {!!membersPresent?.length && (
          <Table striped bordered hover className="mb-0">
            <thead>
              <tr>
                <th>Member</th>
                <th>Location</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                membersPresent.map((member) => (
                  <tr key={member.id_user}>
                    <td>{`${member.name_first} ${member.name_last}`}</td>
                    <td>{member.location}</td>
                    <td>
                      <a href={`tel:${member.phone}`}>{member.phone}</a>
                    </td>
                    <td>
                      <a href={`mailto:${member.email}`}>{member.email}</a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </DashboardCard>
    </>
  );
};

export default MembersPresent;
